import moment from "moment-timezone";

export const strings = {
  today: moment().tz("Asia/Jakarta").format("YYYY-MM-DD"),
  tomorrow: moment().add(1, "days").tz("Asia/Jakarta").format("YYYY-MM-DD"),
  token: localStorage.getItem("token"),
  api: {
    host: "https://api.sumselprov.info/api/v1",
    storage: "https://storage.surabah.tech/upload",
    HEADER_KEY: "mantapjiwa",
    JWT_KEY: "Aj1257Xi202",
  },
  image: {
    authBackground: "url(/img/background.png)",
    logo: "/img/logo-colored.png",
    logo2: "/img/logo-colored.png",
    favicon: "",
    primaryColor: "#1445e4",
    buttonColor: "#6e17f7",
    color1: "#0675b5",
    color2: "#10a8f0",
    color3: "#0077b6",
    color4: "#FF0000",
    color5: "#005578",
    color6: "#FFAA00",
  },
  auth_form: {
    isUnavailableAccount: "Belum punya akun? ",
    sign_up: "Daftar",
    sign_in: "Masuk",
    alreadyHaveAccount: "Sudah punya akun? ",
    create_account: "Create a new account:",
  },
  default: {
    title_app: "Dashboard",
  },
  navigation: {
    login: "/auth/login",
    register: "/auth/register",
    main: "/app",
    path: {
      login: "login",
      register: "register",
      projects:"/app/projects",
      forgot_password: "forgot-password",
      activate: "activate",
      resend: "resend",
      please: "please",
      logout: "logout",
      dashboard: "/app/dashboard",
      contents: "/app/contents",
      my_projects: "/app/my-projects",
      verification_projects: "/app/verification-projects",
      open_projects: "/app/open-projects",
      my_targets: "/app/my-targets",
      categorization: "/app/categorization",
      finished_projects: "/app/finished_projects",
      detail_content: "/app/detail-content",
      categories: "/app/categories",
      subcategories: "/app/subcategories",
      wajib_pajak: "/app/wajib-pajak",
      proyek_opd: "/app/proyek-opd",
      vendor: "/app/vendor",
      detail_category: "/app/detail-category",
      detail_project: "/app/detail-project",
      detail_project_verification: "/app/detail-project-verification",
      detail_vendor: "/app/detail-vendor",
      detail_project_opd: "/app/detail-project-opd",
      detail_wajib: "/app/detail-wajib",
      ambil_project: "/app/ambil-project",
      detail_categorization: "/app/detail-categorization",
      detail_user: "/app/detail-user",
      users: "/app/users",
      profile: "/app/profile",
      settings: "/app/settings",
      roles: "/app/roles",
      tags: "/app/tags",
      detail_tag: "/app/detail-tag",
      detail_subcategory: "/app/detail-subcategory",
      static_target: "/app/target"
    },
  },
};
